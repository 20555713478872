<template>
  <v-container class="fill-height wena my-0" fluid>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="4" lg="4" xl="4">
        <v-system-bar color="secondary" dark class="pb-7">
          <v-spacer></v-spacer>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn text color v-on="on">
                <flag :iso="currentFlag" v-bind:squared="false" />
                <v-icon right color="primary">mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="(entry, index) in languagess"
                :key="`${index}010`"
                @click="changeLocale(entry)"
              >
                <v-list-item-title>
                  <flag :iso="entry.flag" v-bind:squared="false" />
                  &nbsp;{{ entry.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-system-bar>
        <v-form ref="form">
          <v-card class="pb-10">
            <v-row justify="center">
              <v-col cols="4" md="5">
                <v-responsive class="pt-10">
                  <v-img width="300px" src="/KENMARE.png"></v-img>
                </v-responsive>
              </v-col>
            </v-row>
            <v-card-title class="subtitle-1 text-center justify-center pb-10 px-12">
              <p style="display: inline-block">{{ $t("enter_email_user") }}</p>
            </v-card-title>
            <v-card-text class="px-10">
              <v-alert
                v-show="showError"
                dense
                outlined
                type="error"
                class="text-center"
              >
                {{ error }}
              </v-alert>
              <v-text-field
                v-model="emailOrMobilePhone"
                :placeholder="`${$t('email_mobile_number')}`"
                outlined
                type="text"
                dense
                required
                :rules="requiredRules"
              ></v-text-field>
              <div class="d-flex flex-column align-center">
                <v-btn
                  :disabled="isLoading"
                  color="primary"
                  class="mb-3"
                  block
                  @click.prevent="submit"
                  >{{ $t("search") }}</v-btn
                >
                <v-btn
                  class="mx-2"
                  type="reset"
                  block
                  @click.prevent="cancelRecover()"
                  >{{ $t("cancel") }}</v-btn
                >
              </div>
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import { required, email, minLength } from "vuelidate/lib/validators";
import { SEARCH_USER_BY_EMAIL_OR_MOBILE_PHONE } from "./../graphql/mutation.gql";
import { formatError } from "@/utils";
import { mapActions } from "vuex";
import accessLevelMixins from "@/mixins/access-level";

export default {
  name: "Recover",
  mixins: [accessLevelMixins],
  data: () => ({
    currentFlag: "",
    emailOrMobilePhone: "",
    error: undefined,
    noInternet: false,
    isLoading: false,
    showError: false,
    user: {
      name: "",
      email: "",
      password: "",
    },
  }),
  created() {
    if (localStorage.getItem("flag") && localStorage.getItem("lang")) {
      this.$i18n.locale = localStorage.getItem("lang");
      this.currentFlag = localStorage.getItem("flag");
    }

    this.noInternet = window.navigator.onLine ? false : true;
    if (this.noInternet) {
      this.error = "No Internet Connection";
      this.showError = true;
    }
  },

  computed: {
    languagess: function() {
      return [
        { flag: "gb", language: "en", title: "English" },
        // { flag: "fr", language: "fr", title: "Français" },
        { flag: "pt", language: "pt", title: "Português" },
      ];
    },
    emailErrors() {
      const errors = [];
      const email = this.$v.user.email;
      if (!email.$dirty) {
        return errors;
      }
      !email.required && errors.push(`${this.$t("email_required")}!`);
      !email.email && errors.push(`${this.$t("enter_valid_email")}!`);

      return errors;
    },
    requiredRules() {
      return [(v) => !!v || `${this.$t("email_required")}!`];
    },
  },

  methods: {
    ...mapActions({
      setUserState: "auth/setUser",
    }),
    changeLocale(locale) {
      this.$i18n.locale = locale.language;
      this.currentFlag = locale.flag;

      localStorage.setItem("lang", locale.language);
      localStorage.setItem("flag", locale.flag);
    },
    cancelRecover() {
      this.$router.push("/login");
    },
    async submit() {
      this.isLoading = true;
      if (this.emailOrMobilePhone) {
        try {
          const { data } = await this.$apollo.mutate({
            mutation: SEARCH_USER_BY_EMAIL_OR_MOBILE_PHONE,
            variables: {
              emailOrMobilePhone: this.emailOrMobilePhone,
            },
          });

          const findedType = this.emailOrMobilePhone.search("@");
          this.$router.push({
            path: `recover_password`,
            query: {
              any: { id: data.findedAccount.id, email: data.findedAccount.email, telephone: data.findedAccount.telephone },
              typeEnter: { type: findedType > -1 ? "email" : "number" },
            },
          });
        } catch (error) {
          this.error = formatError(error.message);
          this.showError = true;
        } finally {
          this.isLoading = false;
        }
      } else {
        this.error = this.$t("email_required");
        this.showError = true;
        this.isLoading = false;
      }
    }
  },
}
</script>

<style scoped>
.wena {
  background: #2e4c33;
}
</style>